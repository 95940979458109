import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { chartIntervals } from '@truescope-web/react/lib/components/charts/enums';
import { getDefaultInterval } from '@truescope-web/react/lib/components/widgets/DatePicker';
import { createLookup } from '@truescope-web/utils/lib/arrays';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { sortByString } from '@truescope-web/utils/lib/strings';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { updateWorkspace } from '../../../components/Config/configReducer';
import { chartDataSource, reportSectionTypes } from '../ReportConstants';

export const syncReportTemplateToConfig = (updatedReportTemplate, workspace, dispatchConfig) => {
	const reportTemplates = [...workspace.reportTemplates];
	const reportTemplateIndex = reportTemplates.findIndex(
		({ report_template_id }) => report_template_id === updatedReportTemplate.report_template_id
	);

	const configReportTemplate = {
		report_template_id: updatedReportTemplate.report_template_id,
		name: updatedReportTemplate.name,
		subject_merged: updatedReportTemplate.subject_merged,
		subtitle_merged: updatedReportTemplate.subtitle_merged,
		sections: (updatedReportTemplate.sections || []).map((section) => ({
			section_id: section.section_id,
			section_type: section.section_type,
			title: section.title
		}))
	};

	if (reportTemplateIndex === -1) {
		reportTemplates.push(configReportTemplate);
		sortByString(reportTemplates, 'name');
	} else {
		reportTemplates[reportTemplateIndex] = { ...reportTemplates[reportTemplateIndex], ...configReportTemplate };
	}

	dispatchConfig(
		updateWorkspace({
			reportTemplates,
			reportTemplatesLookup: createLookup(reportTemplates, 'report_template_id')
		})
	);
};

export const getLayoutSettingValue = (itemOptions, section, property) => {
	if (isNullOrUndefined(section)) {
		return null;
	}

	const overriddenSettings =
		property === 'serialized_filters.sort' || property === 'serialized_filters.desc' ? section : section.layout_settings;

	if (section.layout_settings.override_default_settings) {
		return get(overriddenSettings, property) || get(section.layout_settings, property);
	}

	return get(itemOptions, property);
};

/**
 * gets media item sections that are overridden and have media items in them
 * @param {*} reportTemplate
 * @returns
 */
const getMediaItemSectionsWithItems = (reportTemplate) => {
	return (
		reportTemplate.sections?.filter(
			(section) => section.section_type === reportSectionTypes.mediaItems && section.total_item_count > 0
		) || []
	);
};

/**
 * gets all language codes from a report template
 * @param {*} reportTemplate
 * @returns
 */
export const getLanguageCodes = (reportTemplate) => {
	const defaultLanguageCode = reportTemplate.item_options.language_code || '';
	return getMediaItemSectionsWithItems(reportTemplate).map((section) =>
		section.layout_settings.override_default_settings ? section.layout_settings.language_code || '' : defaultLanguageCode
	);
};

/**
 * gets all include contextual summary flags from a report template
 * @param {*} reportTemplate
 * @returns
 */
export const getIncludeContextualSummaryFlags = (reportTemplate) => {
	const defaultIncludeContextualSummaryFlag = reportTemplate.item_options.include_contextual_summary || false;
	return getMediaItemSectionsWithItems(reportTemplate).map((section) =>
		section.layout_settings.override_default_settings
			? section.layout_settings.include_contextual_summary || false
			: defaultIncludeContextualSummaryFlag
	);
};

/**
 * when a section or media item is removed, we need to resync the search filters with the sections and items
 * @param {*} reportTemplate
 */
export const syncChartDataSources = (sections, sectionMetadataLookup) =>
	sections.map((section) => {
		if (section.section_type === reportSectionTypes.charts) {
			return {
				...section,
				charts: section.charts.map((chart) =>
					chart.chartJson.dataSource === chartDataSource.reportSection
						? syncChartDataSource({ ...chart }, sectionMetadataLookup)
						: chart
				)
			};
		}
		return section;
	});

const syncChartDataSource = (chart, sectionMetadataLookup) => {
	//clear any removed report template section ids. but only update the collection if they actually change
	const validSectionIds = chart.chartJson.reportTemplateSectionIds.filter(
		(sectionId) => !isNullOrUndefined(sectionMetadataLookup[sectionId])
	);
	if (!isEqual(chart.chartJson.reportTemplateSectionIds, validSectionIds)) {
		chart.chartJson.reportTemplateSectionIds = validSectionIds;
	}

	//only rebuild the search filters if they've actually changed
	const updatedSearchFilter = createSectionFilter(chart.chartJson.reportTemplateSectionIds, sectionMetadataLookup);
	if (
		!moment(chart.chartJson.search_filter.publication_date_from).isSame(updatedSearchFilter.publication_date_from) ||
		!isEqual(chart.chartJson.search_filter.item_ids, updatedSearchFilter.item_ids)
	) {
		chart.chartJson.search_filter = updatedSearchFilter;
	}

	if (
		!isNullOrUndefined(chart.chartJson.chart_interval_id) &&
		!isNullOrUndefined(chart.chartJson.search_filter.publication_date_option)
	) {
		//for charts with intervals, we need to adjust the interval
		chart.chartJson.chart_interval_id =
			getDefaultInterval(
				chart.chartJson.search_filter.publication_date_option,
				chart.chartJson.search_filter.publication_date_from,
				chart.chartJson.search_filterpublication_date_to
			) || chartIntervals.day;
	}

	return chart;
};

/**
 * creates a section filter, used by charts to search by item id
 * @param {string[]} reportTemplateSectionIds
 * @param {oject} sectionMetadataLookup
 * @returns
 */
export const createSectionFilter = (reportTemplateSectionIds, sectionMetadataLookup) => {
	const { itemIds, oldestItemDate } = reportTemplateSectionIds.reduce(
		(lookup, sectionId) => {
			lookup.itemIds.push(...(sectionMetadataLookup[sectionId]?.itemIds || []));

			const sectionOldestItemDate = moment(sectionMetadataLookup[sectionId].oldestItemDate);
			if (sectionOldestItemDate.isValid() && sectionOldestItemDate.isBefore(lookup.oldestItemDate)) {
				lookup.oldestItemDate = sectionOldestItemDate;
			}

			return lookup;
		},
		{ itemIds: [], oldestItemDate: moment().utc() }
	);

	if (arrayIsNullOrEmpty(itemIds)) {
		//when there's no item ids, we dont set the date filter. this stops the chart from trying to load any data
		return { item_ids: [] };
	}

	return {
		item_ids: itemIds,
		publication_date_option: dateOptionsLookup.custom,
		//use last 30 days. this doesnt matter too much, because an actual publish will use the correct dates
		publication_date_from: oldestItemDate.toISOString()
	};
};

/**
 * replaces the merge field with literal strings
 * @param {*} input
 * @returns
 */
export const replaceMergeFields = (input, itemCount = 0) =>
	stringIsNullOrEmpty(input)
		? input
		: input
				.replace(/{long_date}/gi, moment().format('dddd, MMMM DD, YYYY'))
				.replace(/{short_date}/gi, moment().format('DD/MM/YYYY'))
				.replace(/{us_short_date}/gi, moment().format('MM/DD/YYYY'))
				.replace(/{day_of_week}/gi, moment().format('dddd'))
				.replace(/{item_count}/gi, `${itemCount}`);

/**
 * merges a new report with an old report
 * @param {object} newReportTemplate
 * @param {object} oldReport Template
 * @returns
 */
export const mergeReportTemplates = (newReportTemplate, oldReportTemplate) => {
	const oldSectionsLookup = oldReportTemplate.sections.reduce((sectionsLookup, section) => {
		sectionsLookup[section.section_id] = section;
		return sectionsLookup;
	}, {});

	const sections =
		newReportTemplate.sections?.map((newSection) => {
			const oldSection = oldSectionsLookup[newSection.section_id];
			if (isNullOrUndefined(oldSection)) {
				return newSection;
			}
			switch (newSection.section_type) {
				case reportSectionTypes.mediaItems: {
					//if you've loaded some items on the page, we need to make sure that they dont disappear
					//so as long as the count is the same, and the items dont match, keep the old copy
					if (newSection.total_item_count === oldSection.total_item_count) {
						newSection.items = oldSection.items;
					}
					break;
				}
				case reportSectionTypes.charts:
				case reportSectionTypes.html:
				case reportSectionTypes.aiSummary:
					break;
				default:
					throw new Error(`unknown report section type '${newSection.section_type}'`);
			}
			return newSection;
		}) || [];

	return {
		...newReportTemplate,
		sections
	};
};
